import React from "react";

import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";

import CustomPage from "../components/CustomPage";

export default function Home() {
  return (
    <CustomPage
      pageHref="/"
      breadcrumbs={[
        {
          text: "Home",
          href: "#",
        },
        {
          text: "Dashboard",
          href: "#",
        },
      ]}
    >
      <SpaceBetween size="xxs">
        <div style={{ marginTop: 20 }}>
          <Box variant="h2" color="inherit">
            Welcome to Nebham Hub
          </Box>
          <Box variant="p" color="inherit">
            One-Stop Platform to manage products, inventory, receive orders,
            fulfill shipments, and more.
          </Box>
        </div>
      </SpaceBetween>
    </CustomPage>
  );
}
